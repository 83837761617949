<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('商品对账单.账单日期')" prop="id">
                <a-date-picker v-model="queryParam.billDate" style="width: 100%" valueFormat="YYYYMMDD">
                  <a-icon slot="suffixIcon" type="smile" />
                </a-date-picker>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('商品对账单.账单月份')" prop="id">
                <a-month-picker v-model="queryParam.billMonth" style="width: 100%" valueFormat="YYYYMM">
                  <a-icon slot="suffixIcon" type="smile" />
                </a-month-picker>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('销售订单.订单编号')" prop="id">
                <a-input v-model="queryParam.orderNo" @pressEnter="handleQuery" :maxLength="18" :placeholder="$t('通用.输入.请输入')+$t('销售订单.订单编号')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('销售订单.三方订单号')" prop="outTradeNo">
                <a-input v-model="queryParam.outTradeNo" @pressEnter="handleQuery" :maxLength="40" :placeholder="$t('通用.输入.请输入')+$t('销售订单.三方订单号')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('销售订单.订单状态')" prop="orderStatusList">
                <a-select  mode="multiple" :placeholder="$t('通用.输入.请选择')+$t('销售订单.订单状态')"
                           @change="orderStatusListChange"
                           style="width: 100%" v-model="queryParam.orderStatusList" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.OrderSellStatusEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('商品.商品编号')" prop="goodsCode">
                <a-input v-model="queryParam.goodsCode" @pressEnter="handleQuery" :maxLength="16" :placeholder="$t('通用.输入.请输入')+$t('商品.商品编号')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('商品.商品名称')" prop="goodsName">
                <a-input v-model="queryParam.goodsName" @pressEnter="handleQuery" :maxLength="16" :placeholder="$t('通用.输入.请输入')+$t('商品.商品名称')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('销售订单.支付方式')" prop="payTypeList">
                <a-select  mode="multiple" :placeholder="$t('通用.输入.请选择')+$t('销售订单.支付方式')"
                           style="width: 100%" v-model="queryParam.payTypeList" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.OrderPayTypeEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

<!--              <a-col :md="6" :sm="24">
              <a-form-item :label="$t('销售订单.买家名称')" prop="buyerName">
                <a-input v-model="queryParam.buyerName" :maxLength="20" :placeholder="$t('通用.输入.请输入')+$t('销售订单.买家名称')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('销售订单.买家信息')" prop="userMobile">
                <a-input v-model="queryParam.userMobile" :maxLength="20" :placeholder="$t('通用.输入.请输入')+$t('销售订单.手机号或邮箱')" allow-clear/>
              </a-form-item>
            </a-col>-->
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('系统用户.开始时间')" prop="createBeginTime">
                <a-date-picker style="width: 100%" v-model="queryParam.createBeginTime"
                               :show-time="{defaultValue: moment('00:00:00', 'HH:mm:ss')}"
                               valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('系统用户.结束时间')" prop="createEndTime">
                <a-date-picker style="width: 100%" v-model="queryParam.createEndTime"
                               :show-time="{defaultValue: moment('23:59:59', 'HH:mm:ss')}"
                               valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
              </a-form-item>
            </a-col>

            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('通用.按钮.查询')}}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('通用.按钮.重置')}}</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl" />
        </a-modal>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="handleExport" v-hasPermi="['order:sell:export']">
          <a-icon type="download" />{{$t('通用.按钮.导出')}}
        </a-button>
        <a-button type="primary" :disabled="multiple"  @click="handleSelectExport" v-hasPermi="['order:sell:export']">
          <a-icon type="download" />{{$t('通用.按钮.选中导出')}}
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>

      <!-- 数据展示 -->
      <a-table
        :scroll="{ x: '130%' }"
        :loading="loading"
        size="small"
        @change="handleSortChange"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="payType" slot-scope="text, record">
          {{ record.payTypeName }}
        </span>
        <span slot="orderStatus" slot-scope="text, record">
             {{ record.orderStatusName }}
        </span>
        <span slot="activityId" slot-scope="text, record">
             {{ record.activityName }}
        </span>
        <span slot="source" slot-scope="text, record">
             {{ record.sourceName }}
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => getTotal(total)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageGoods,listGoods} from '@/api/finance/goods'
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from 'vuex'
import moment from 'moment';
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Sell',
  components: {
    CustomDictTag,
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      activeKey: null,
      pageSizeOptions: ['5', '10', '20', '50'],
      filteredInfo: {},
      sortedInfo : {},
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        id: undefined,
        billDate: undefined,
        billMonth: undefined,
        buyerId: undefined,
        isPay: undefined,
        goodsCode: undefined,
        goodsName: undefined,

        outTradeNo: undefined,
        buyerName: undefined,
        createBeginTime: undefined,
        createEndTime: undefined,
        payType: undefined,
        payTime: undefined,
        userMobile: undefined,
        orderStatus: undefined,
        orderStatusList: undefined,
        refundStatusList: undefined,
        payTypeList: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: this.$t('商品对账单.账单日期'),
          dataIndex: 'billDate',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center',
        },
        {
          title: this.$t('商品对账单.账单月份'),
          dataIndex: 'billMonth',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center',
        },
        {
          title: this.$t('销售订单.订单编号'),
          dataIndex: 'orderNo',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center',
          width: 160,
        },
        {
          title: this.$t('销售订单.订单状态'),
          dataIndex: 'orderStatus',
          scopedSlots: { customRender: 'orderStatus' },
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center'
        },
        {
          title: this.$t('销售订单.订单来源'),
          dataIndex: 'source',
          scopedSlots: { customRender: 'source' },

          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center'
        },
        {
          title: this.$t('销售订单.下单时间'),
          dataIndex: 'orderTime',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center'
        },
        {
          title: this.$t('销售订单.支付方式'),
          dataIndex: 'payType',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          scopedSlots: { customRender: 'payType' },
          align: 'center'
        },
        {
          title: this.$t('销售订单.三方订单号'),
          dataIndex: 'outTradeNo',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center'
        },
        {
          title: this.$t('销售订单.支付时间'),
          dataIndex: 'payTime',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center'
        },
        /*{
          title: this.$t('商品.商品编号'),
          dataIndex: 'goodsCode',
          align: 'center'
        },*/
        {
          title: this.$t('商品.商品名称'),
          dataIndex: 'goodsName',
          align: 'center'
        },
        {
          title: this.$t('商品.SKU编号'),
          dataIndex: 'skuCode',
          align: 'center'
        },
        {
          title: this.$t('商品.SKU名称'),
          dataIndex: 'skuName',
          align: 'center'
        },
        {
          title: this.$t('销售订单.商品数量'),
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'goodsCount',
          align: 'center'
        },
        {
          title: this.$t('商品.商品价格'),
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'originalPrice',
          align: 'center'
        },
        {
          title: this.$t('销售订单.运费金额'),
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'shippingPayAmount',
          align: 'center'
        },
        {
          title: this.$t('销售订单.优惠金额'),
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'discountAmount',
          align: 'center'
        },
        {
          title: this.$t('销售订单.平台抽成'),
          // sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'platformMoney',
          align: 'center'
        },
        {
          title: this.$t('销售订单.商品实付金额'),
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'payAmount',
          align: 'center'
        },
        /*{
          title: this.$t('活动记录.活动名称'),
          dataIndex: 'activityId',
          scopedSlots: { customRender: 'activityId' },
          align: 'center'
        },*/
      ]
    }
  },
  filters: {
  },
  created () {
    this.getOrderRouteList()
  },
  watch: {
    $route:'getOrderRouteList'
  },
  mounted() {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  methods: {
    moment,
    getTotal(total) {
      return this.$t('通用.文本.共')+total+this.$t('通用.文本.条')
    },
    getOrderRouteList() {
      let params = this.$route.params;
      if (params.buyerId) {
        this.queryParam.buyerId = params.buyerId
        this.queryParam.isPay = params.isPay
      }
      this.getList()
    },
    /** 查询销售订单列表 */
    getList () {
      this.loading = true
     pageGoods(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    orderStatusListChange() {
      this.queryParam.orderStatus = undefined;
    },
    //切换tab
    changeTab(index) {
      if (index == null) {
        index = undefined;
      }
      this.queryParam.orderStatus = index;
      this.getList();
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        id:undefined,
        billDate: undefined,
        billMonth: undefined,
        buyerId:undefined,
        isPay:undefined,
        goodsCode: undefined,
        goodsName: undefined,
        outTradeNo: undefined,
        buyerName: undefined,
        orderStatusList: undefined,
        refundStatusList: undefined,
        createBeginTime: undefined,
        createEndTime: undefined,
        payType: undefined,
        payTypeList: undefined,
        payTime: undefined,
        userMobile:undefined,
        orderStatus: undefined,
        refundStatus: undefined,
        pageNum: 1,
        pageSize: 10
      };
      this.handleQuery()
    },
    handleSortChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
      if (sorter.order) {
        /** 排序字段 sortField **/
        /** 排序方式  sortOrder**/
        this.queryParam.sortField = sorter.columnKey
        this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.getList()
      } else {
        this.queryParam.sortField = '';
        this.queryParam.sortOrder = ''
        this.getList()
      }
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    handleOrders(record) {
      ordersSell({id:record.id})
        .then(() => {
          this.onSelectChange([], [])
          this.getList()
          const message = this.$t('销售订单.接单成功')
          this.$message.success(
            message,
            3
          )
        })
    },

    handleCancel(record) {
      var that = this
      this.$confirm({
        title: this.$t('通用.文本.确认取消'),
        onOk () {
          return cancelSell({id:record.id})
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              const message = that.$t('通用.文本.取消成功')
              that.$message.success(
                message,
                3
              )
            })
        },
        onCancel () {}
      })
      this.getList();
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出当前条件下所有数据而非选中数据'),
        onOk () {
          that.download('finance/goods/export',
            that.queryParam
          , `商品对账单_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    handleSelectExport () {
      var that = this
      let queryParam = {"idList": this.ids}
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出选中数据'),
        onOk () {
          that.download('finance/goods/export', queryParam, `商品对账单_${new Date().getTime()}.xlsx`)
          that.ids = []
          that.multiple = true;
          that.selectedRowKeys=[]
        },
        onCancel () {
          that.ids = []
          that.multiple = true;
          that.selectedRowKeys=[]
        }
      })
    }

  }
}
</script>
<style>
.innerTable>.ant-card-body {
  padding: 0px;
}
</style>
